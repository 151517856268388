// cardimgs.js
/*import card1 from '../../assets/Cards/1.png'
import card2 from '../../assets/Cards/2.png'
import card3 from '../../assets/Cards/3.png'
import card4 from '../../assets/Cards/4.png'
import card5 from '../../assets/Cards/5.png'
import card6 from '../../assets/Cards/6.png'
import card7 from '../../assets/Cards/7.png'
import card8 from '../../assets/Cards/8.png'
import card9 from '../../assets/Cards/9.png' */
import card10 from '../../assets/Cards/10.png'
import card11 from '../../assets/Cards/11.png'
import card12 from '../../assets/Cards/12.png'
import card13 from '../../assets/Cards/13.png'
import card14 from '../../assets/Cards/14.png'
import card15 from '../../assets/Cards/15.png'
import card16 from '../../assets/Cards/16.png'
import card17 from '../../assets/Cards/17.png'
import card18 from '../../assets/Cards/18.png'
import card19 from '../../assets/Cards/19.png'
import card20 from '../../assets/Cards/20.png'
import card21 from '../../assets/Cards/21.png'
import card22 from '../../assets/Cards/22.png'

let array = [
    card10,
    card11,
    card12,
    card13,
    card14,
    card15,
    card16,
    card17,
    card18,
    card19,
    card20,
    card21,
    card22
]

export default array