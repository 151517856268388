// rankincrements.js

let array = [
    0,
    125,
    225,
    335,
    450,
    570,
    690,
    820,
    960,
    1110,
    1270,
    1440,
    1640,
    1840,
    2090,
    2340,
    2615,
    2890,
    3190,
    3490,
    3790,
    4115,
    4500
]

export default array