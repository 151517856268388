import React, { Component } from 'react';
import '../../App.css';

class Membership extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="background-static-all">
                <h2 className="page-header">Membership</h2>
            </div>
        );
    }
}


export default Membership;